
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { IreFactorObject } from '@/store/modules/IRE/FactorsModule';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-factors-bathrooms-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ireFactorsbathroomsUpdating'), [
          translate('ire'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_IRE_FACTOR,
        route.params.id
      );

      const ireFactorsBathrooms = ref<IreFactorObject>(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const ireFactorsBathroomsSchema = Yup.object().shape({
        titleEn: Yup.string()
          .required(() => translate('TITLE_EN_IS_REQUIRED_FIELD'))
          .label('titleEn'),
        titleAr: Yup.string()
          .required(() => translate('TITLE_AR_IS_REQUIRED_FIELD'))
          .label('titleAr'),
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_IRE_FACTOR, {
          id: ireFactorsBathrooms.value?.id,
          data: values,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_IRE_FACTOR_BATHROOM'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'ire-factors-bathrooms-listing' });
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        submitButton,
        translate,
        ireFactorsBathroomsSchema,
        goBack,
        ireFactorsBathrooms,
        can,
      };
    },
  });
